<template>
  <div>
    <!-- 搜索 -->
    <el-input
      placeholder="搜索"
      class="input-with-select"
      v-model="query.title"
      @keyup.enter.native="searchEnterFun"
      ><!-- v-model="input3" -->
      <!-- 搜索图标按钮 -->
      <el-button
        slot="append"
        icon="el-icon-search"
        class="searchButton"
        @click="goSearch(query.title)"
      >
      </el-button>
    </el-input>
  </div>
</template>

<script>
export default {
  data () {
    return {
      query: {
        title: ''
      }
    }
  },
  methods: {
    // 去搜索页面
    goSearch (title) {
      /* 如果没输入东西就提醒输入 */
      if (this.query.title === '') {
        this.$message('请输入关键词')
      }
      /* 输入东西就跳转去searchPage页面并把title（输入的内容）带过去 */
      if (this.query.title !== '') {
        this.$router.push({ path: '/homepage/searchPage', query: { title } })
      }
    },
    // 回车搜索
    searchEnterFun (e) {
      var keyCode = window.event ? e.keyCode : e.which
      var val = e.target.value
      if (keyCode === 13 && val) {
        this.goSearch(val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.input-with-select {
  float: right;
  top: 40px;
  width: 1.338rem;
  height: 34px;
  font-size: 0.0833rem;
}

/deep/.el-input__inner{
  width:1.171rem ;
  font-size: 0.0833rem;
}

/deep/.input-with-select .searchButton {
  width: 0.223rem;
  height: 34px;
  background-color: #2e88e4;
  color: #fff;
  border-top: 0;
  border-bottom: 0;
  padding: 2px 0px 0px 1px;
  border-radius: 0px;
}

.el-icon-search {
  float: right;
  top: 0px;
  padding: 0 10px;
}

.input-with-select {
  position: absolute;
  top: 50px;
  right: 400px;
}
</style>
