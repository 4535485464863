// 主页接口
import http from '../request.js'

export default {
  // 获取cgzpfirst的数据
  getPictures: data => {
    return http({
      url: '/api/home/category/index',
      data
    })
  },
  // 文章列表(获取cgzpsecond页面数据(下面9个图))
  getExhibits: data => {
    return http({
      url: '/api/home/article/index',
      data
    })
  },
  // 获取cpzpthird的文章详情
  getArticle: data => {
    return http({
      url: '/api/home/article/info',
      data
    })
  }
}
