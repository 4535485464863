/*
 * @Description: api 模块化处理
 */
const moduleFiles = require.context('./modules', true, /\.js$/)
const module = moduleFiles.keys().reduce((module, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = moduleFiles(modulePath)
  module[moduleName] = value.default
  return module
}, {})

export default {
  module
}
