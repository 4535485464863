import axios from 'axios'
import { Message } from 'element-ui'
// import store from '../store'
// import NProgress from 'nprogress'

/* 配置请求域名 */
export let baseURL = null
baseURL = 'https://stdzj-api.ozkoalas.cn' // 测试环境
// baseURL = 'http://36.133.7.151:8084' // 正式环境

/* 创建axios的一个实例 */
var instance = axios.create({
  baseURL,
  // baseURL: '/http', // 配置跨域
  timeout: 15000, // 请求超时毫秒数
  method: 'post'
})

// instance({
//   url: '/api/account/login'
// }).then((response) => {
//   console.log(response)
// })

// instance.post('/api/account/login').then(response => {
//   console.log(response.data)
// })

/* 请求拦截器 */
instance.interceptors.request.use(
  config => {
    // 进度条开启
    // NProgress.start()

    // // 登陆后请求携带 token
    if (localStorage.getItem('Authorization')) {
      config.headers.token = localStorage.getItem('Authorization')
    }
    return config
  },
  error => {
    // 抛出服务器响应错误
    return Promise.reject(error)
  }
)

/* 响应拦截器 */
instance.interceptors.response.use(
  response => {
    // 进度条关闭
    // NProgress.done()

    const res = response.data
    // console.log('请求结果', res)

    // 判断服务器返回的数据
    if (res.code && res.code !== 1) {
      Message({
        message: res.msg,
        type: 'error',
        duration: 3 * 1000
      })
      // 未登录/没有权限
      // 如果前端拿到状态码为-90001，就清除token信息并跳转到登录页面
      if (res.code === -90001) {
        // removeItem删除key
        localStorage.removeItem('Authorization')
        this.$router.push('/login')
      }
      return Promise.reject(res.msg)// res.mg:账号或密码错误
    } else {
      return response.data
    }
  },
  error => {
    // 进度条关闭
    // NProgress.done()

    // 抛出服务器响应错误
    Message({
      message: error,
      type: 'error',
      duration: 3 * 1000
    })
    return Promise.reject(error)
  }
)

export default instance
