// 主页接口
import http from '../request.js'

export default {
  // 获取地震知识文章数据
  getearthquakeList: data => {
    return http({
      url: '/api/home/article/index',
      data
    })
  },
  // 获取地震知识热门知识数据
  getHotKnowledgeList: data => {
    return http({
      url: '/api/home/article/hot',
      data
    })
  },
  // 获取地震知识详情文章数据
  getEarthquakeArticle: data => {
    return http({
      url: '/api/home/article/info',
      data
    })
  }
}
