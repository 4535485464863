// 主页接口
import http from '../request.js'

export default {
  // 场馆活动文章详情跳转
  getActivity: data => {
    return http({
      url: '/api/home/active/index',
      data
    })
  },
  // 避震攻略 文章标题、内容
  getBzgl: data => {
    return http({
      url: '/api/home/article/index',
      data
    })
  },
  // 地震知识 文章标题、内容渲染
  getKnowledge: data => {
    return http({
      url: '/api/home/article/index',
      data
    })
  },
  // 获取避震攻略分类数据
  getClassify: data => {
    return http({
      url: '/api/home/category/index',
      data
    })
  },
  // 获取避震攻略热门知识数据
  getHotKnowledge: data => {
    return http({
      url: '/api/home/article/hot',
      data
    })
  },
  // 获取避震攻略文章数据
  getBzglArticle: data => {
    return http({
      url: '/api/home/article/info',
      data
    })
  },
  // 搜索
  getSearchArticle: data => {
    return http({
      url: '/api/home/article/search',
      data
    })
  },
  // 轮播图
  getCarousel: data => {
    return http({
      url: '/api/home/article/banner',
      data
    })
  },
  // 避震攻略分类图
  getClassifyPic: data => {
    return http({
      url: '/api/home/category/index',
      data
    })
  }
}
