import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
import empty from '@/layout/empty.vue'
Vue.use(VueRouter)

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    redirect: '/homepage',
    hidden: true,
    meta: {}
  },
  {
    path: '/homepage',
    component: Layout,
    redirect: '/homepage/home',
    children: [
      {
        path: 'home',
        component: () => import('@/views/homepage/home.vue')
      },
      {
        path: 'searchPage',
        meta: {
          title: '搜索'
        },
        component: () => import('@/views/search/searchPage.vue')
      },
      {
        path: 'bzglfirst',
        name: 'bzglfirstStrategy',
        meta: {
          title: '避震攻略分类'
        },
        component: () => import('@/views/homepage/bzglfirst.vue')
      },
      {
        path: 'bzglsecond',
        meta: {
          title: '避震攻略分类',
          breadspan: false
        },
        component: empty,
        children: [
          {
            path: '',
            meta: {
              title: '避震攻略列表',
              breadspan: false
              // breadcrumb: false
            },
            component: () => import('@/views/homepage/bzglsecond.vue')
          },
          {
            path: 'bzglthird',
            name: 'bzglthirdParticulars',
            meta: {
              title: '详情',
              breadspan: false,
              breadcolor: false
            },
            component: () => import('@/views/homepage/bzglthird.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/third',
    component: Layout,
    redirect: '/third/cgzpfirst',
    children: [
      {

        path: 'cgzpfirst',
        meta: {
          title: '展品分类'
        },
        component: empty,
        redirect: '/third/cgzpfirst',
        children: [
          {
            path: '',
            meta: {
              title: '展品分类'
            },
            component: () => import('@/views/venueexhibits/cgzpfirst.vue')
          },
          // http://localhost:8080/#/third/cgzpfirst/cgzpsecond
          {
            path: 'cgzpsecond',
            meta: {
              title: '展品列表',
              breadspan: false
            },
            component: empty,
            children: [
              {
                path: '',
                meta: {
                  title: '展品列表',
                  // false的话隐藏这个路由
                  breadcrumb: false
                },
                component: () => import('@/views/venueexhibits/cgzpsecond.vue')
              },
              {
                path: 'cgzpthird',
                name: 'cgzpthirdParticulars',
                meta: {
                  title: '详情',
                  breadspan: false,
                  breadcolor: false
                },
                component: () => import('@/views/venueexhibits/cgzpthird.vue')
              }
            ]

          }
        ]
      }
    ]
  },
  {
    path: '/forth',
    component: Layout,
    redirect: '/forth/cghdfirst',
    children: [
      {
        path: 'cghdfirst',
        meta: {
          title: '场馆活动'
        },
        component: empty,
        children: [
          {
            path: '',
            name: 'cghdfirstActivity',
            meta: {
              title: '场馆活动'
            },
            component: () => import('@/views/venueactivity/cghdfirst.vue')
          },
          {
            path: 'cghdsecond',
            name: 'cghdsecondParticulars',
            meta: {
              title: '详情',
              breadspan: false,
              breadcolor: false
            },
            component: () => import('@/views/venueactivity/cghdsecond.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/fifth',
    component: Layout,
    redirect: '/fifth/dzzsfirst',
    children: [
      {
        path: 'dzzsfirst',
        component: empty,
        meta: {
          title: '地震知识'
        },
        children: [
          {
            path: '',
            name: 'dzzsfirstKnowledge',
            meta: {
              title: '地震知识'
            },
            component: () => import('@/views/earthquakeknowledge.vue/dzzsfirst.vue')
          },
          {
            path: 'dzzssecond',
            name: 'dzzssecondParticulars',
            meta: {
              title: '详情',
              breadspan: false,
              breadcolor: false
            },
            component: () => import('@/views/earthquakeknowledge.vue/dzzssecond.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/sixth',
    name: '参观服务',
    redirect: '/sixth/cgfxfirst',
    component: Layout,
    children: [
      {
        path: 'cgfxfirst',
        name: 'cgfxfirstServe',
        meta: {
          title: '参观服务'
        },
        component: () => import('@/views/serve/servefirst.vue')
      }
    ]
  }

]

const router = new VueRouter({
  routes,
  // 对于页面跳转，全部都返回到页面顶部。
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
