<template>
  <div class="page">
    <!-- 顶部 -->
    <div class="box-top">
      <div class="box-intop">
        <div class="top-word">
          <div class="top-word1">汕头地震局科普馆</div>
          <div class="top-word2">
            SHANTOU SEISMOLOGICAL BUREAU SCIENCE MUSEUM
          </div>
        </div>
        <!-- 搜索 -->
        <search></search>
      </div>
    </div>
    <router-view class="routerView" />
    <!-- 底部黑色部分 -->
    <footer class="footerDiv">
      <div class="box-bottom">
        <div class="box-inbottom">
          <!-- 左边内容 -->
          <div class="bottom-word">
            <!-- 左边的徽章 -->
            <div class="bottom-left">
              <img src="@/assets/home/zhuye/picture20.png" alt="" />
            </div>
            <div class="bottom-right">
              <div>主办 : 汕头市地震局 承办 : 汕头市地震局</div>
              <div>地址：广东省汕头市地震局 邮编：515000</div>
              <div>粤ICP备00000000-2号 网站标识码：000000000</div>
            </div>
          </div>
          <!-- 右边二维码 -->
          <div class="QRcode">
            <img
              src="@/assets/home/zhuye/QRcode.png"
              width="100%"
              height="100%"
            />
            <div class="codeWord">手机版</div>
          </div>
        </div>
      </div>
    </footer>
    <!--   返回顶部
    <div class="gotop" @click="goTop()">^</div>-->
  </div>
</template>

<script>
import Search from '../components/search.vue'
export default {
  name: 'layout',
  components: { Search },
  data () {
    return {
      index: 0
    }
  },
  methods: {
    // goTop () {}
  }
}
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.box-top {
  flex-shrink: 0;
  height: 150px;
}
.box-intop {
  width: 1200px;
  margin: 0 auto;
}

.top-word {
  float: left;
  margin-top: 30px;
  display: inline;
}

.top-word1 {
  width: 271px;
  height: 32px;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #004ea2;
}

.top-word2 {
  width: 274px;
  height: 7px;
  font-size: 7px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #004ea2;
  margin-top: 10px;
}

.el-input {
  width: 130px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.input-with-select {
  float: right;
  top: 40px;
  width: 257px;
}

/deep/ .el-input__inner {
  height: 34px;
  border-radius: 0px;
}
.icon-search {
  float: right;
  top: 40px;
  width: 43px;
  height: 34px;
  background-color: #2e88e4;
}

.el-icon-search {
  float: right;
  top: 0px;
  padding: 0 10px;
}

/deep/.el-input-group__append,
.el-input-group__prepend {
  padding: 0 10px;
  border: 0px;
}

.routerView {
  flex-grow: 2;
}

.footerDiv {
  flex-shrink: 0;
}

.box-bottom {
  background-color: #31353d;

  width: 100%;
}

.box-inbottom {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.bottom-word {
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0px;
  color: #b9b9b9;
  padding-top: 34px;
  display: flex;
}

.gotop {
  position: fixed;
  right: 40px;
  text-align: center;
  bottom: 60px;
  width: 20px;
  height: 20px;
  line-height: 25px;
  border-radius: 50%;
  background: #fff;
  padding: 10px;
  cursor: pointer;
}

.bottom-left {
  padding-bottom: 20px;
}

.bottom-right {
  margin-left: 20px;
}

.QRcode {
  width: 85px;
  height: 85px;
  margin-top: 25px;
  margin-right: 70px;
}

.codeWord {
  color: #fff;
  margin-left: 20px;
  font-size: 15px;
}
</style>
