// 主页接口
import http from '../request.js'

export default {
  // 获取参观服务数据
  getServe: data => {
    return http({
      url: '/api/home/article/visitService',
      data
    })
  }
}
