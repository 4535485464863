// 主页接口
import http from '../request.js'

export default {
  // 获取活动内容数据
  getActivityList: data => {
    return http({
      url: '/api/home/active/index',
      data
    })
  },
  // 获取分类选项（全部  场馆体验  VR体验  科普讲座
  getlist: data => {
    return http({
      url: '/api/home/category/index',
      data
    })
  },
  // 获取广告图
  getAdvertisingPic: data => {
    return http({
      url: '/api/home/article/banner',
      data
    })
  },
  // 活动文章详情
  getActivityArticle: data => {
    return http({
      url: '/api/home/active/info',
      data
    })
  },
  // 获取热门活动数据
  getHotActivityArticle: data => {
    return http({
      url: '/api/home/active/hot',
      data
    })
  },
  // 提交按钮
  pushForm: data => {
    return http({
      url: '/api/home/apply/save',
      data
    })
  }
}
